import React from "react";
import PropTypes from "prop-types";
import { Cell, Grid, Row } from "../FoundationGrid";

function Section(props) {
  let classes = "section";
  if (props.className) classes = `${classes} ${props.className}`;
  if (props.full) classes = `full ${classes}`;
  if (props.small) classes = `small ${classes}`;
  else classes = `default ${classes}`;

  if (props.noPadding) classes = `nopadding ${classes}`;

  return (
    <section className={classes} style={props.style}>
      <Content props={props} />
    </section>
  );
}

const Content = ({ props }) => {
  if (props.full) return props.children;
  else
    return (
      <Grid>
        <Row>
          <Cell sm={24}>{props.children}</Cell>
        </Row>
      </Grid>
    );
};

Section.propTypes = {
  default: PropTypes.bool,
};

export default Section;
