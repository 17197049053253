import React from "react";
import PropTypes from "prop-types";

/**
 * Foundation Grid Container (className grid-container)
 */
function Grid(props) {
  const { full, fluid, style, className } = props;

  const getClasses = () => {
    return `${full ? "full" : ""} ${fluid ? "fluid" : ""} grid-container ${
      className || ""
    }`;
  };

  return (
    <div className={getClasses()} style={style}>
      {props.children}
    </div>
  );
}

Grid.propTypes = {
  full: PropTypes.bool,
  fluid: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

/**
 * Foundation Row (className grid-x)
 */
function Row(props) {
  const { margin, padding, style, className } = props;

  const getClasses = () => {
    let classes = `grid-x`;

    if (margin) {
      if (margin === "x") classes += " grid-margin-x";
      else if (margin === "y") classes += " grid-margin-y";
      else if (margin === "xy") classes += " grid-margin-x grid-margin-y";
    }

    if (padding) {
      if (padding === "x") classes += " grid-padding-x";
      else if (padding === "y") classes += " grid-padding-y";
      else if (padding === "xy") classes += " grid-padding-x grid-padding-y";
    }

    classes = `${classes} ${className || ""}`;
    return classes;
  };

  return (
    <div className={getClasses()} style={style}>
      {props.children}
    </div>
  );
}

Row.propTypes = {
  margin: PropTypes.oneOf(["x", "y", "xy"]),
  padding: PropTypes.oneOf(["x", "y", "xy"]),
  style: PropTypes.object,
  className: PropTypes.string,
};

/**
 * Foundation Cell (className cell)
 * sm/md/lg = Integer between 1 and 24 for the width of the column (sm=small/Smartphones, md=medium/Tablets, lg=large/Desktop)
 * smo/mdo/lgo = Integer between 0 and 23 for an offset (space before column)
 */
function Cell(props) {
  let { sm, md, lg, smo, mdo, lgo, left, center, right, style, className } =
    props;

  const defaultValue = 24;
  const defaultOffsetValue = 0;

  if (!sm) sm = defaultValue;
  if (!md) md = sm;
  if (!lg) lg = md;

  if (!smo) smo = defaultOffsetValue;
  if (!mdo) mdo = smo;
  if (!lgo) lgo = mdo;

  const getClasses = () => {
    let classes = `cell small-${sm} medium-${md} large-${lg} small-offset-${smo} medium-offset-${mdo} large-offset-${lgo}`;

    if (left) classes += " text-left";
    if (center) classes += " text-center";
    if (right) classes += " text-right";

    classes = `${classes} ${className || ""}`;
    return classes;
  };

  return (
    <div className={getClasses()} style={style}>
      {props.children}
    </div>
  );
}

Cell.propTypes = {
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  smo: PropTypes.number,
  mdo: PropTypes.number,
  lgo: PropTypes.number,
  left: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export { Grid, Row, Cell };
