import i18n from "../config/i18n.json";
import config from "../config/config.json";

const translate = (path, locale) => {
  return i18n[path][locale];
};

const getNextLanguage = (locale) => {
  let firstLanguage = null;
  let nextLanguage = null;

  let current = null;

  const { languages } = config;
  Object.keys(languages).forEach((languageKey) => {
    if (!firstLanguage) firstLanguage = languageKey;
    if (current && !nextLanguage) nextLanguage = languageKey;

    if (languageKey === locale) current = languageKey;
  });

  if (!nextLanguage && firstLanguage) nextLanguage = firstLanguage;

  return nextLanguage;
};

export { translate, getNextLanguage };
