import { Link } from "gatsby";
import React from "react";
import { Cell, Grid, Row } from "../components/FoundationGrid";
import Section from "../components/Section";
import config from "../config/config.json";
import { getUrl } from "../helpers/helpers";
import { translate } from "../helpers/I18n";

function Error404(props) {
  return (
    <div className="page-error404">
      <Section>
        <h1>Oh no, page not found!</h1>

        <p className="lead">
          Sorry, but the page you requested could not be found. Maybe you were
          looking for one of the pages listed below?
        </p>
        <p className="lead">
          If not, <Link to={getUrl("contact", "en")}>please let me know</Link>{" "}
          about that missing page. Thanks a lot!
        </p>
      </Section>
      <Section>
        <Links />
      </Section>
    </div>
  );
}

const Links = () => {
  const languages = config.languages;

  return (
    <div className="__error404-links">
      <Grid>
        <Row margin="xy">
          {Object.keys(languages).map((languageKey) => {
            const language = languages[languageKey];
            const navItems = config.mainMenu.nav.items[languageKey];

            return (
              <Cell sm={24} md={8} key={languageKey}>
                <p>
                  <strong>
                    {language.icon} {language.name}
                  </strong>
                </p>
                <ul>
                  {navItems.map((navItem, index) => {
                    return (
                      <li key={index}>
                        <Link to={getUrl(navItem.identifier, languageKey)}>
                          {translate(`menu.${navItem.identifier}`, languageKey)}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    </div>
  );
};

export default Error404;
